import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'

import Axios from '@components/Axios'
import Card from '@components/Card'
// import Modal from '@components/Modal'
import Button from '@components/Button'

import FormGroup from './components/FormGroup'

const AddEdit = props => {
  let { close, item = {}, updateList } = props

  const schema = {
    enableReinitialize: true,
    initialValues: { ...item },
    validationSchema: Yup.object().shape({
      descriptionMachine: Yup.string().required('REQUIRED'),
      category: Yup.string().required('REQUIRED'),
      description: Yup.string().required('REQUIRED'),
      co2: Yup.number().required('REQUIRED'),
      ch4: Yup.number().required('REQUIRED'),
      n2o: Yup.number().required('REQUIRED'),
      unit: Yup.string().required('REQUIRED'),
      energy: Yup.number().required('REQUIRED'),
      co2Biogenic: Yup.number().required('REQUIRED'),
    })
  }

  const footer = submit => {
    return <Button onClick={submit}>INPUT_ADD_CARD_SEND</Button>
  }

  const onSuccess = () => {
    close()
    updateList()
  }

  const add = ({ handleSubmit, ...formik }) => {
    return (
      <Card footer={() => footer(handleSubmit)}>
        <FormGroup {...props} formik={formik} />
      </Card>
    )
  }

  // const edit = ({ handleSubmit, ...formik }) => {
  //   return (
  //     <Modal
  //       close={close}
  //       open={!!item?._id}
  //       title="INPUT_EDIT_MODAL_TILE"
  //       actions={() => actions({ handleSubmit })}
  //     >
  //       <FormGroup {...props} formik={formik} />
  //     </Modal>
  //   )
  // }

  // const actions = ({ handleSubmit }) => {
  //   return (
  //     <div className="actions">
  //       <Button color="secondary" onClick={close}>
  //         INPUT_BUTTON_CLOSE
  //       </Button>
  //       <Button onClick={handleSubmit}>INPUT_ADD_CARD_SEND</Button>
  //     </div>
  //   )
  // }

  let params = {}
  if (item?._id) {
    params = {
      method: 'put',
      others: item._id
    }
  } else {
    params = {
      method: 'post'
    }
  }

  const onSubmit = ({ values, submit, resetForm }) => {
    submit({ params: values })
    resetForm()
  }

  return (
    <div className="AddEdit">
      <Axios api="inputs.addFuels" {...params} onSuccess={onSuccess}>
        {({ submit }) => (
          <Formik
            {...schema}
            onSubmit={(values, event) => onSubmit({ ...event, values, submit })}
          >
            {formik => add(formik)}
            {/* {formik => (item?._id ? edit(formik) : add(formik))} */}
          </Formik>
        )}
      </Axios>
    </div>
  )
}

export default AddEdit

