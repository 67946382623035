import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'

import Utils from '@utils'
import Icon from '@components/Icon'
import Table from '@components/Table'

import './styles.scss'

const List = ({ list = [], setItem, ...props }) => {

  const isNumber = (value) => {
    return Utils.numberFormart(value, 5)
  }

  const isNumberWithFormat = (value, format) => {
    return Utils.numberFormart(value, format)
  }

  const columns = [
    { title: 'FUELS_LIST_DESCRIPTION_MACHINE', field: 'descriptionMachine' },
    { title: 'FUELS_LIST_TYPE_COMBUSTION', field: 'category' },
    { title: 'FUELS_LIST_USED_FUEL', field: 'description' },
    {
      title: 'INPUTS_LIST_CO2', field: 'co2',
      component: ({ co2 }) => {
        return isNumber(co2)
      }
    },
    {
      title: 'INPUTS_LIST_CH4', field: 'ch4',
      component: ({ ch4 }) => {
        return isNumber(ch4)
      }
    },
    {
      title: 'INPUTS_LIST_N2O', field: 'n2o',
      component: ({ n2o }) => {
        return isNumber(n2o)
      }
    },
    {
      title: 'INPUTS_LIST_CO2_BIOGENIC', field: 'co2Biogenic',
      component: ({ co2Biogenic }) => {
        return isNumber(co2Biogenic)
      }
    },
    { title: 'INPUTS_LIST_UNITY', field: 'unit' },
    {
      title: 'INPUTS_LIST_ENERGY', field: 'energy',
      component: ({ energy }) => {
        return isNumber(energy)
      }
    },
    {
      title: 'INPUTS_LIST_AVERAGE', field: 'energy',
      component: ({ averageConsumption }) => {
        return isNumberWithFormat(averageConsumption, 5)
      }
    },
    {
      title: 'INPUTS_LIST_OPTIONS',
      className: 'options',
      component: item => {
        if (item.type === 'Material') {
          return (
            <IconButton onClick={() => setItem(item)} className="list-edit">
              <Icon size={30} name="edit" />
            </IconButton>
          )
        } else {
          return '-'
        }
      }
    }
  ]

  return (
    <div className="List">
      <Table {...props} data={list} columns={columns} title="FUELS_LIST_TABLE_TITLE" />
    </div>
  )
}

export default List

List.propTypes = {
  list: PropTypes.array,
  setItem: PropTypes.func,
  updateList: PropTypes.func
}
