import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'

import Utils from '@utils'
import Icon from '@components/Icon'
import Table from '@components/Table'
import Toast from '@utils/toast.utils'
import { http } from '@components/Axios'


import './styles.scss'

const List = ({ list = [], setItem, ...props }) => {

  const isNumber = (value) => {
    return Utils.numberFormart(value, 5)
  }

  const downloadCsv = async (item) => {
    try {
      props.setLoading(true)
      let { data } = await http({
        api: 'results.filterMaterial',
        others: { materials: `${item.description}` },
      })
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(data);
      link.target = '_blank';
      link.download = 'report.csv';
      link.click();
    } catch (error) {
      const message = Utils.getValue(error, 'response.data.message')
      if (message) {
        Toast.sendMessage('error', message, false)
      } else {
        Toast.sendMessage('error', 'AXIOS_ERRO', 10000)
      }
    } finally{
      props.setLoading(false)
    }
  }

  const columns = [
    { title: 'INPUTS_LIST_DESCRIPION', field: 'description' },
    { title: 'INPUTS_LIST_CATEGORY', field: 'category' },
    {
      title: 'INPUTS_LIST_CO2', field: 'co2',
      component: ({ co2 }) => {
        return isNumber(co2)
      }
    },
    {
      title: 'INPUTS_LIST_CH4', field: 'ch4',
      component: ({ ch4 }) => {
        return isNumber(ch4)
      }
    },
    {
      title: 'INPUTS_LIST_N2O', field: 'n2o',
      component: ({ n2o }) => {
        return isNumber(n2o)
      }
    },
    {
      title: 'INPUTS_LIST_CO2_BIOGENIC', field: 'co2Biogenic',
      component: ({ co2Biogenic }) => {
        return isNumber(co2Biogenic)
      }
    },
    { title: 'INPUTS_LIST_UNITY', field: 'unit' },
    {
      title: 'INPUTS_LIST_ENERGY', field: 'energy',
      component: ({ energy }) => {
        return isNumber(energy)
      }
    },
    {
      title: 'INPUTS_LIST_PHASES',
      component: ({ phases }) => {
        return phases.join(', ')
      }
    },
    {
      title: 'INPUTS_LIST_OPTIONS',
      className: 'options',
      component: item => {
        if (item.type === 'Material') {
          return (
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-around', 
              alignItems: 'center'
            }}>
              <IconButton onClick={() => setItem(item)} className="list-edit">
                <Icon size={30} name="edit" />
              </IconButton>
              <IconButton onClick={() => downloadCsv(item)} className="list-edit">
                <Icon size={30} name="GetApp" />
              </IconButton>
            </div>
          )
        } else {
          return '-'
        }
      }
    }
  ]

  return (
    <div className="List">
      <Table {...props} data={list} columns={columns} title="INPUTS_LIST_TABLE_TITLE" />
    </div>
  )
}

export default List

List.propTypes = {
  list: PropTypes.array,
  setItem: PropTypes.func,
  updateList: PropTypes.func
}
