import React from 'react'
import { Grid } from '@material-ui/core'

import Utils from '@utils'
import Input from '@components/Input'

const FormGroup = props => {
  const { formik, phases, subTypes } = props
  const { handleBlur, handleChange, values = {}, errors } = formik
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Input
            name="descriptionMachine"
            onBlur={handleBlur}
            onChange={handleChange}
            label="FUELS_INPUT_DESCRIPTION_MACHINE"
            value={Utils.getValue(values, 'descriptionMachine')}
            error={Utils.getValue(errors, 'descriptionMachine')}
            helperText={Utils.getValue(errors, 'descriptionMachine')}
          />
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs>
            <Input
              name="category"
              onBlur={handleBlur}
              onChange={handleChange}
              label="FUELS_INPUT_TYPE_COMBUSTION"
              value={Utils.getValue(values, 'category')}
              error={Utils.getValue(errors, 'category')}
              helperText={Utils.getValue(errors, 'category')}
            />
          </Grid>
          <Grid item xs>
            <Input
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
              label="FUELS_INPUT_USED_FUEL"
              value={Utils.getValue(values, 'description')}
              error={Utils.getValue(errors, 'description')}
              helperText={Utils.getValue(errors, 'description')}
            />
          </Grid>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Input
            name="co2"
            type="number"
            onBlur={handleBlur}
            onChange={handleChange}
            label="INPUTS_ADD_INPUT_LABEL_CO2"
            info="INPUTS_ADD_INPUT_LABEL_CO2_INFO"
            value={Utils.getValue(values, 'co2')}
            error={Utils.getValue(errors, 'co2')}
            helperText={Utils.getValue(errors, 'co2')}
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Input
            name="ch4"
            type="number"
            onBlur={handleBlur}
            onChange={handleChange}
            label="INPUTS_ADD_INPUT_LABEL_CH4"
            value={Utils.getValue(values, 'ch4')}
            error={Utils.getValue(errors, 'ch4')}
            helperText={Utils.getValue(errors, 'ch4')}
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Input
            name="n2o"
            type="number"
            onBlur={handleBlur}
            onChange={handleChange}
            label="INPUTS_ADD_INPUT_LABEL_N2O"
            value={Utils.getValue(values, 'n2o')}
            error={Utils.getValue(errors, 'n2o')}
            helperText={Utils.getValue(errors, 'n2o')}
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Input
            type="number"
            name="co2Biogenic"
            onBlur={handleBlur}
            onChange={handleChange}
            label="INPUTS_ADD_INPUT_LABEL_CO2BIOGENIC"
            value={Utils.getValue(values, 'co2Biogenic')}
            error={Utils.getValue(errors, 'co2Biogenic')}
            helperText={Utils.getValue(errors, 'co2Biogenic')}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Input
            name="unit"
            onBlur={handleBlur}
            onChange={handleChange}
            label="INPUTS_ADD_INPUT_LABEL_UNIT"
            value={Utils.getValue(values, 'unit')}
            error={Utils.getValue(errors, 'unit')}
            helperText={Utils.getValue(errors, 'unit')}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Input
            type="number"
            name="energy"
            onBlur={handleBlur}
            onChange={handleChange}
            label="INPUTS_ADD_INPUT_LABEL_ENERGY"
            value={Utils.getValue(values, 'energy')}
            error={Utils.getValue(errors, 'energy')}
            helperText={Utils.getValue(errors, 'energy')}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Input
            type="number"
            name="averageConsumption"
            onBlur={handleBlur}
            onChange={handleChange}
            label="FUELS_INPUT_AVERAGE_CONSUMPTION"
            value={Utils.getValue(values, 'averageConsumption')}
            error={Utils.getValue(errors, 'averageConsumption')}
            helperText={Utils.getValue(errors, 'averageConsumption')}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default FormGroup
