import React, { useState } from 'react'

// @material-ui/icons
import Tabs from '@components/Tabs'
import Axios from '@components/Axios'

import List from './components/List'
import AddEdit from './components/AddEdit'

import './styles.scss'

const Fuels = () => {
  const [run, setRun] = useState(1)
  const [list, setList] = useState([])
  const [item, setItem] = useState({})
  const [subTypes, setSubTypes] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [params, setParams] = useState({ page: 0, limit: 10 })

  const onSuccess = ({ data, ...value }) => {
    setList(data)
    setPagination(value)
  }

  const onSuccessEnumerations = ({ data }) => {
    console.log('data', data)
    const { subtypes } = data || {}
    setSubTypes(subtypes)
  }

  const updateList = () => {
    setRun(run + 1)
  }

  const close = () => {
    const item = {
      description: ' ',
      co2: ' ',
      ch4: ' ',
      n2o: ' ',
      unit: ' ',
      energy: ' ',
      co2Biogenic: ' ',
    }
    setItem(item)
  }

  const tabs = [
    {
      icon: 'list',
      name: 'INPUT_TAB_LIST',
      content: <List
        list={list}
        loading={loading}
        setItem={setItem}
        updateList={updateList}
        customPagination={{ search: 'name', params, setParams, pagination }}
      />
    },
    {
      icon: 'list',
      name: 'INPUT_TAB_ADD_EDIT',
      content: <AddEdit
        item={item}
        close={close}
        subTypes={subTypes}
        updateList={updateList}
      />
    },
  ]

  return (
    <div className="Fuel">
      <Axios
        run
        api="constants.enumerations"
        onSuccess={onSuccessEnumerations}
      >
        <Axios
          api="inputs.getFuels"
          others={params}
          loading={false}
          onSuccess={onSuccess}
          onLoading={setLoading}
          run={JSON.stringify({ run, ...params })}
        >
          <Tabs headerColor="primary" tabs={tabs} />
        </Axios>
      </Axios>
    </div>
  )
}

export default Fuels
